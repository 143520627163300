
import { defineComponent, ref, provide } from 'vue'

export default defineComponent({
  setup () {
    const newProductsRef = ref<HTMLElement>()
    const newAuthorsRef = ref<HTMLElement>()

    provide('bind[newProductsRef]', (el: HTMLElement) => {
      newProductsRef.value = el
    })

    provide('bind[newAuthorsRef]', (el: HTMLElement) => {
      newAuthorsRef.value = el
    })

    const prevNewProducts = () => {
      const el: any = newProductsRef.value
      el.tns.goTo('prev')
    }
    const nextNewProducts = () => {
      const el: any = newProductsRef.value
      el.tns.goTo('next')
    }
    const prevNewAuthors = () => {
      const el: any = newAuthorsRef.value
      el.tns.goTo('prev')
    }
    const nextNewAuthors = () => {
      const el: any = newAuthorsRef.value
      el.tns.goTo('next')
    }

    return {
      prevNewProducts,
      nextNewProducts,
      prevNewAuthors,
      nextNewAuthors
    }
  }
})
